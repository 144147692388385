<template>
  <div class="wrapper">
    <NavBar class="nav-bar" />
    <navigation class="navigation" />
    <router-view class="router-wrapper" />
  </div>
</template>

<script type="text/javascript">
import Navigation from './components/mobile/NaviGation.vue'
import NavBar from './components/NavBar.vue'
export default {
  data() {
    return {
    }
  },
  components:{
    Navigation,
    NavBar
  }
}
</script>

<style lang="scss">
.wrapper {
  position: relative;
}

body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@media only screen and (min-width: 600px) {
  .navigation {
    display: none;
  }
  .router-wrapper {
    //width: 65%;
    height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .nav-bar {
    display: none;
  }
}

//fonts
@font-face {
  font-family: Casta;
  src: url("~@/assets/fonts/Casta-Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: Casta-light;
  src: url("~@/assets/fonts/Casta-Light.otf");
  font-weight: 100;
}
@font-face {
  font-family: Casta-Medium;
  src: url("~@/assets/fonts/Casta-MediumExpanded.otf");
  font-weight: 100;
}
@font-face {
  font-family: monospace;
  src: url("~@/assets/fonts/SpaceMono-Bold.ttf");
  font-weight: 100;
}
@font-face {
  font-family: monospace-light;
  src: url("~@/assets/fonts/SpaceMono-Regular.ttf");
  font-weight: 100;
}
</style>
