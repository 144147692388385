<template>
  <div class="portfolio-wrapper">
    <div class="text-lay-out">
      <div class="instagram-content">
    <div v-if="loading">Bilder werden geladen...</div>
    <div v-if="!loading" class="image-grid">
      <div v-for="image in imageOnly" :key="image.id" class="image-item">
        <img :src="image.media_url" :alt="image.caption || 'Instagram Bild'" />
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      images: [],
      loading: true, 
    };
  },
  methods: {
    async fetchInstagramImages() {
      const accessToken = 'IGQWRQTS1KX2FIQy1MNExwcXUwSWFoajJkbXlEdXdxWUNmb2xvY1p3RkhMbGV0WlpYQl8yUXItZA2F4dHExb09uek5XNFZArY3lHd2xFUEszTkRFMDV6aG10TGU2QjB1SlNFOHFZANUpsVmd2S2h3NUxxZATdVX25FU1kZD';
      const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=${accessToken}`;
      
      try {
        const response = await axios.get(apiUrl);
        this.images = response.data.data;
      } catch (error) {
        console.error('Fehler beim Abrufen der Bilder:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    imageOnly() {
      return this.images.filter(image => image.media_type !== 'VIDEO');
    }
  },
  mounted() {
    this.fetchInstagramImages();
  }
}
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); 
  gap: 15px; 
  margin: 20px 0; 
}

.image-item img {
  width: 100%;
  height: auto;
  border-radius: 5px; 
  object-fit: cover; 
  height: 400px;
}

.instagram-content {
  padding: 16px;
  background-image: url("@/assets/img/portfolioBg.png");
}

.portfolio-wrapper {
  font-family: monospace;
  font-size: 3vh;
  width: 100%;
  height: auto;
  background-image: url("@/assets/img/portfolioBg.png");
  background-size: initial;
  display: flex;
  flex-direction: column;
}
.text-dec-none {
  text-decoration: none;
  color: antiquewhite;
}
.text-lay-out {
  width: 80%;

  align-self: center;
  margin-top: 15%;
}
.head-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
@media only screen and (min-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .portfolio-wrapper {
    width: 60%;
    height: auto;
    background-image: url("@/assets/img/portfolioBg.png");
    background-size: initial;
    display: flex;
    flex-direction: column;
  }
}
</style>