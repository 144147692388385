<template>
  <div class="impressum-container">
    <div class="impressum-contend">
      <div class="impressum-a">
        <h4>Angaben gemäß §5:</h4>
        gunst & tinte <br />
        tattookunst <br />
        Propsteihof 3 <br />
        36100 Petersberg <br />
        Inhaberin: Theresa Blank
      </div>
      <div class="dot-sep">*</div>
      <div class="impressum-b">
        <h4>Kontakt:</h4>
        Telefon: 016091841449 <br />
        E-Mail: hi@gunstundtinte.de <br />
        <br />
        Verantwortliche gem. § 55 Abs. 2 RStV für die Inhalte im <br />
        Übrigen: <br />
        <br />
        Theresa Blank <br />
        Propsteihof 3 <br />
        36100 Petersberg <br />
        <br />

        Idee, Konzept: Mona Bianca Dürer <br />
        https://monabiancaduerer.cargo.site <br />
        <br />
      </div>
      <div class="dot-sep">*</div>
      <div class="impressum-c">
        <h4>Copyright:</h4>
        Die Rechte der Bilder und Inhalte der Seite sind Eigentum von Theresa
        Blank
      </div>
      <div class="dot-sep">*</div>
      <div class="impressum-d">
        <h4>Haftung für Inhalte:</h4>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
        
        }
    },
}
</script>
<style>
.impressum-container {
  left: 0%;
  position: absolute;
  display: flex;
  flex-direction: column;

  height: 100vh;
  background-image: url("@/assets/img/greenBg.jpg");
  background-size: cover;
  align-items: center;
  font-family: monospace-light;
  color: antiquewhite;
  font-size: 40%;
}
@media only screen and (min-width: 600px) {
  .impressum-container {
    left: 0%;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100vh;
    background-image: url("@/assets/img/greenBg.jpg");
    background-size: cover;
    align-items: center;
    font-family: monospace-light;
    color: antiquewhite;
    font-size: 70%;
  }
}
.impressum-contend {
  width: 80%;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.impressum-a {
  align-content: left;
}
.impressum-b {
}
.impressum-c {
}
.dot-sep {
  margin-top: 5%;
  color: #ec5728;
}
</style>