<template>
    <div class="kontakt-container">
        <div class="kontakt-form" v-if="!showForm">
            <h3>Deine Tattooanfrage</h3>
            <p>
                Mit dem folgenden Anfrageformular stelle ich sicher, dass ich alle notwendigen Informationen für dein
                neues Tattoo erhalte, ohne dass etwas fehlt.
                Ich werde mich nach Erhalt in den kommenden Wochen mit zwei Terminvorschlägen bei dir melden und hierbei
                versuchen, deine Wunschzeiten zu berücksichtigen, was je nach Motiv und Größe jedoch nur bedingt möglich
                sein wird.
                Ich arbeite von montags bis donnerstags.
                <br><br>
                Bei Neukunden und größeren Motiven behalte ich mir vor, ggf. eine Anzahlung zu nehmen, die dann mit dem
                Endpreis verrechnet wird.
                Solltest du deinen Termin nicht wahrnehmen können, muss deine Absage mindestens sieben Werktage vorher
                erfolgen, um deine Anzahlung zurück zu bekommen. Danach wird sie für den organisatorischen Mehraufwand
                meinerseits einbehalten.
                Im Krankheitsfall schauen wir individuell, wie wir das Problem lösen.
                Erscheinst du ohne Absage zu deinem vereinbarten Termin nicht oder kommst zu spät, sodass wir den Termin
                in der geplanten Zeit nicht mehr realisieren können, steht dir eine Schadensersatzleistung in voller
                Höhe des vereinbarten Budgets bevor (§615 BGB).
                <br><br>
                Ich freue mich auf deine Anfrage und tolle Projekte, die wir zusammen gestalten.
                🧡
            </p>
            <button class="form-button" @click="showFormular">Zur Anmeldung</button>
        </div>
        <div style="padding:16px" v-if="!formSubmitted && showForm">
            <form @submit.prevent="submitForm" class="form-container">
                <div class="form-group">
                    <label for="firstName">Vor - und Nachname:</label>
                    <input type="text" v-model="formData.name" id="firstName" required />
                </div>

                <div class="form-group">
                    <label for="email">Telefonnummer:</label>
                    <input type="text" v-model="formData.phoneNumber" id="phoneNumber" required />
                </div>

                <div class="form-group">
                    <label for="email">E-Mail:</label>
                    <input type="email" v-model="formData.email" id="email" required />
                </div>

                <div class="form-group">
                    <label for="bodyPart">Körperstelle:</label>
                    <input type="text" v-model="formData.bodyPart" id="bodyPart" required />
                </div>

                <div class="form-group">
                    <label for="description">Motivbeschreibung:</label>
                    <textarea v-model="formData.description" id="description" required></textarea>
                </div>

                <div class="form-group">
                    <label for="photos">Beispielfotos (max. 4):</label>
                    <input type="file" @change="handleFileUpload" id="photos" multiple accept="image/*" />
                    <p v-if="formData.photos.length > 4" style="color:red;">Maximal 4 Bilder erlaubt!</p>
                </div>

                <div class="form-group">
                    <label for="notes">Extra Anmerkungen:</label>
                    <textarea v-model="formData.notes" id="notes"></textarea>
                </div>

                <div class="form-group">
                    <label for="date">Wunschtag:</label>
                    <input type="date" v-model="formData.desiredDate" id="date" required />
                </div>

                <div class="form-group">
                    <button :disabled="isFormInvalid" type="submit">Absenden</button>
                </div>
            </form>
        </div>

        <div style="padding:16px" v-if="formSubmitted" class="confirmation-message">
            <p style="text-align: center">Vielen Dank! Deine Anfrage wurde erfolgreich versendet. Ich werde mich bald bei dir melden.</p>
            <button @click="resetForm">Zurück zum Formular</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KontaktFormular',
    data() {
        return {
            formSubmitted: false,
            showForm: false,
            formData: {
                name: '',
                email: '',
                phoneNumber: '',
                bodyPart: '',
                description: '',
                photos: [],
                notes: '',
                desiredDate: ''
            }
        };
    },
    computed: {
        isFormInvalid() {
            return (
                !this.formData.name ||
                !this.formData.email ||
                //!this.formData.bodyPart ||
                //!this.formData.description ||
                !this.formData.desiredDate
            );
        }
    },
    methods: {
        showFormular() {
            this.showForm = true;
        },
        handleFileUpload(event) {
            const files = Array.from(event.target.files);

            if (files.length > 4) {
                this.formData.photos = [];
                alert("Du darfst maximal 4 Bilder hochladen.");
            } else {
                this.formData.photos = files;
            }
        },
        async submitForm() {
            const formData = new FormData();

            formData.append("name", this.formData.name || '');
            formData.append("phoneNumber", this.formData.phoneNumber || '');
            formData.append("email", this.formData.email || '');
            formData.append("bodyPart", this.formData.bodyPart || '');
            formData.append("description", this.formData.description || '');
            formData.append("notes", this.formData.notes || '');
            formData.append("desiredDate", this.formData.desiredDate || '');

            this.formData.photos.forEach((photo, index) => {
                if (photo) {
                    formData.append(`photo_${index}`, photo);
                }
            });

            try {
                await fetch("https://sendformdata-btyvikjviq-uc.a.run.app", {
                    method: "POST",
                    body: formData,
                    headers: {}
                });

                console.log('submitted');
                this.formSubmitted = true;
            } catch (error) {
                console.log("Fehler beim Senden des Formulars:", error);
                alert(JSON.stringify(error));
            }
        },
        resetForm() {
            this.formData = {
                name: '',
                phoneNumber: '',
                email: '',
                bodyPart: '',
                description: '',
                notes: '',
                desiredDate: '',
                photos: [],
            };
            this.showForm = false; 
            this.formSubmitted = false; 
        }
    }
};
</script>

<style>
.kontakt-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url('@/assets/img/offWhite.jpg');
    background-size: cover;
    flex-direction: column;
    text-align: left;
}

.kontakt-form {
    height: 40%;
    width: 90%;
    text-align: center;
    margin-left: 5%;
}

.form-button:hover {
    color: #fe5825;
    background: #203d36;
    cursor: pointer;
}

.form-button {
    border-radius: 15px;
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, .27);
    transition: all .25s;
    padding: 15px 25px;
    border: unset;
    color: #203d36;
    z-index: 1;
    background-image: url('@/assets/img/offWhite.jpg');
    background-size: cover;
    position: relative;
    font-weight: 1000;
    font-size: 17px;
    overflow: hidden;
}

/* Stil für das Formular */
.form-container {
    max-width: 500px;
    /* Maximale Breite des Formulars */
    margin: 0 auto;
    /* Zentriert das Formular auf der Seite */
}

.form-group {
    margin-bottom: 20px;
    /* Abstand zwischen den Feldern */
    display: flex;
    flex-direction: column;
    /* Label oben, Eingabefeld unten */
}

label {
    margin-bottom: 5px;
    /* Abstand zwischen Label und Eingabefeld */
}

input,
textarea,
button {
    width: 100%;
    /* Einheitliche Breite für alle Felder */
    padding: 10px;
    /* Polsterung innerhalb der Felder */
    box-sizing: border-box;
    /* Berücksichtigt Padding in der Breite */
    border: 1px solid #ccc;
    /* Standardrahmen */
    border-radius: 4px;
    /* Leicht abgerundete Ecken */
}

textarea {
    min-height: 100px;
    /* Mindesthöhe für Textbereiche */
}

button {
    background-color: #4CAF50;
    /* Grüne Schaltfläche */
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
    /* Leicht dunkler beim Hover */
}

@media only screen and (min-width: 600px) {
    .kontakt-container {
        width: 60%;
    }
}
</style>