<template>
  <div class="container">
    <div class="navbar-wrapper">
      <div class="nav-box-left">
      <div class="nav-box-left-a">
        <div class="nav-liste">
          <ul>
            <li><router-link to="/portfolio" exact>portfolio</router-link></li>
            <li><router-link to="/atelier" exact>atelier</router-link></li>
            <li><router-link to="/übermich">über mich </router-link></li>
            <li><router-link to="/anfahrt" exact>anfahrt</router-link></li>
            <li><router-link to="/kontakt" exact>kontakt</router-link></li>
            <li>
              <router-link to="/anfrageformular" exact>anfrageformular</router-link>
            </li>
          </ul>
          <img class="sonne-logo" :src="sonne" alt="" />
        </div>
      </div>
      <div class="nav-box-left-b">
        <div class="link-unten">
          <router-link class="link-style" to="/impressum">Impressum</router-link>
        </div>
        <div class="link-unten">
          <router-link class="link-style" to="/datenschutz">Datenschutz</router-link>
        </div>
        <div class="link-unten iG">
          <a href="https://www.instagram.com/theresart.tattoo/" target="blank">
            <img :src="iG" alt="" /></a>
        </div>
        <div class="link-unten ig">
          <a href="https://www.tiktok.com/search?q=theresart.tattoo&t=1679916849857" target="blank"><img :src="tiktok"
              alt="" /></a>
        </div>
      </div>
    </div>
    <div class="navbar-box-right">
      <img :src="seite" alt="" />
    </div>
  </div>
</div>
</template>

<script>
import sonne from '@/assets/img/Grafik_Sonne.svg'
import iG from '@/assets/img/Icon_Instagram_orange.svg'
import tiktok from '@/assets/img/Icon_TikTok_orange.svg'
import seite from '@/assets/img/Logo_Short-long.png'

export default {
  data() {
    return {
      sonne,
      iG,
      tiktok,
      seite
    }
  },
}
</script>
<style>
.container {
  background-color: #203d36;
  height: auto;
}

.navbar-wrapper {
  box-shadow: inset 5px 0px 6px black;
  background-color: #203d36;
  display: flex;
  right: 0;
  position: absolute;
  width: 40%;
  border: 1px solid black;
  min-height: 100vh;
}

.nav-box-left {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100vh;
}

.nav-box-left-a {
  display: flex;
  margin-top: 20%;
  height: 80%;
}

.nav-liste {
  padding-top: 10%;
  margin-left: 10%;
  max-height: 100%;
  max-width: 80%;
}

.nav-liste li {
  text-align: left;
  font-family: Casta;
  color: #ec5728;
  padding: 10px 0;
  list-style-type: "⋄";
}

.nav-liste ul {
  width: 100%;
  height: 80%;
}

.nav-liste a {
  color: #ec5728;
  text-decoration: none;
  font-size: 3.5vw;
}

.nav-liste a.router-link-exact-active,
a:hover {
  color: antiquewhite;
}

.sonne-logo {
  width: 20%;
  height: 20%;
}

.nav-box-left-b {
  display: flex;
  height: 5%;
  justify-content: center;
  border: rgb (130, 34, 34) solid 1px;
}

.link-unten {
  padding: 2%;
}

.link-unten img {
  height: 2em;
  width: 2em;
}

.link-style {
  text-decoration: none;
  color: #ec5728;
}

.link-style:hover {
  color: antiquewhite;
}

.iG {
  height: 25%;
  height: 25%;
}

.navbar-box-right {
  width: 20%;
  height: 100vh;
}

.navbar-box-right img {
  max-height: 98%;
  margin-top: 3%;
}
</style>