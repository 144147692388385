<template>
  <div class="kontakt-container">
    <div class="contend-oben">
      <div class="box-links"><img class="beams" :src="strahlen" alt="" /></div>
      <div class="box-rechts">
        <ul class="list-star">
          <li>TATTOOKUNST BY THERESA</li>
        </ul>
      </div>
    </div>
    <div class="contend-unten">
      <div class="contend-box-a">
        <div class="insta-und-so">
          <div class="insta-links">
            <div class="insta-inhalt">
              <img class="logo-size mail-insta" :src="tel" alt="" />
            </div>
            <div class="insta-inhalt">
              <a href="mailto:hi@gunstundtinte.de"><img style="margin-left: 0" class="logo-size insta-mail" :src="mail" alt="" /></a>
            </div>
          </div>
          <div class="insta-rechts">
            <div class="insta-inhalt">
              <a href="https://www.instagram.com/theresart.tattoo/" target="blank">
                <img class="logo-size insta-mail" :src="iG" alt="https://www.instagram.com" /></a>
            </div>
            <div class="insta-inhalt">
              <a href="https://www.tiktok.com/search?q=theresart.tattoo&t=1679916849857" target="blank">
                <img class="logo-size insta-mail" :src="tikTok" alt="https://www.instagram.com" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="contend-box-b">
        <img class="logo_kontakt" :src="logoKontakt" alt="" />
      </div>
      <div class="contend-box-c">
        <div class="wrapper">
          <div class="schrift-kontakt">
          gunst & tinte <br />
          tattookunst
        </div>
        <div class="dot-sep-k">*</div>
        </div>
        <div class="wrapper">
          <div class="schrift-kontakt">
          Propsteihof 3 <br />
          36100 petersberg
        </div>
        <div class="dot-sep-k">*</div>
        </div>
        <div class="wrapper">
          <div class="schrift-kontakt">
          t: 015117668358 <br />
          hi@gunstundtinte.de
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strahlen from '@/assets/img/strahlen.svg';
import tel from '@/assets/img/Icon_Tel_orange.svg';
import mail from '@/assets/img/Icon_Mail_orange.svg';
import iG from '@/assets/img/Icon_Instagram_orange.svg'
import tikTok from '@/assets/img/Icon_TikTok_orange.svg';
import logoKontakt from '@/assets/img/Logo_Kontakt.svg';

export default {
  data() {
    return {
      tel, mail, iG, tikTok, logoKontakt, strahlen
    }
  },
  name: 'KonTakt'
}
</script>

<style>
.kontakt-container {
  left: 0;
  position: absolute;

  height: 100vh;
  background-image: url('@/assets/img/offWhite.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media only screen and (min-width: 600px) {
  .kontakt-container {
    left: 0;
    top: 0;
    position: absolute;
    width: 60%;
    min-height: 100%;
    background-image: url('@/assets/img/offWhite.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .beams {
    height: 40em;
  }

  .schrift-kontakt {
    margin-top: 5%;
    font-size: 2.2vh;
  }

  .contend-box-c {
    font-family: monospace;
    font-size: xx-small;
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 90vw;
    margin: auto;
  }
}

.contend-oben {
  display: flex;
  flex-direction: row;

  max-width: 100%;
  height: 65%;
}

.box-links {
  position: relative;
  width: 70%;
  max-height: 100%;
}

.beams {
  max-height: 100%;
}

.box-rechts {
  width: 30%;
  margin-right: 20%;
  margin-top: 15%;
}

.contend-unten {
  display: flex;
  flex-direction: column;

  height: 35%;
  max-width: 100%;
  margin-bottom: 10%;
}

.contend-box-a {
  height: 30%;
}

.contend-box-b {
  display: flex;
  height: 40%;
  margin: 0;
}

.contend-box-c .wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.insta-und-so {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.insta-inhalt {
  width: 20%;
}

.insta-links {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  max-height: 100%;
  align-items: center;
}

.insta-rechts {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
}

.insta-mail {
  margin-left: 50%;
}

.deco-none {
  text-decoration: none;
  color: black;
}

.logo-size {
  width: 60%;
  max-height: 60%;
}

.contend-box-b img {
  height: 100%;
  width: 80%;
  margin-left: 10%;
  object-fit: cover;
}

.contend-box-c {
  font-family: monospace;
  font-size: xx-small;
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 90%;
  margin: auto;
}

.schrift-kontakt {
  margin-top: 5%;
  font-size: 1.5vh;
  text-align: center
}

.dot-sep-k {
  margin-top: 7%;
  margin-left: 3.5%;
  margin-right: 3.5%;
  color: #ec5728;
}

.list-star {
  list-style-type: "*";
  text-align: left;
  font-family: casta;
  font-size: 2.5vh;
  font-weight: bold;
}</style>