<template>
  <div class="anfahrt-wrapper">
    <div class="item-center">
      <h1>Anfahrt</h1>
      <div class="breite">
        <p class="align-in-center">
          Propsteihof 3 <br />
          36100 petersberg
        </p>
        <p class="align-in-center">
          Die Straße in der sich mein Shop befindet ist eine Einbahnstraße.
          Bitte achte beim Hineinfahren darauf. Parkmöglichkeiten findest du im
          Parkhaus direkt im Gebäude
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>

<style>
.anfahrt-wrapper {
  left: 0%;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url("@/assets/img/greenBg.jpg");
  background-size: cover;
  align-items: center;
  font-family: monospace-light;
  color: antiquewhite;
  font-size: 100%;
}
@media only screen and (min-width: 600px) {
  .anfahrt-wrapper {
    left: 0%;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100vh;
    background-image: url("@/assets/img/greenBg.jpg");
    background-size: cover;
    align-items: center;
    font-family: monospace-light;
    color: antiquewhite;
    font-size: 3vh;
  }
}
.breite {
  width: 50%;
  margin-left: 25%;
}
.item-center {
  margin-top: 10%;
}
</style>